import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { Layout, Wrapper, SliceZone, SEO, Header, ListingCard, ShareVirality, Section, Title } from "../../components";
import website from '../../../config/website';
import { Hero, HeroInner, Headline, HeroBrightner, StyledWrapper, HeadWrapper, HeroThumb } from './Elements';
import Img from "gatsby-image";
import { StyledHat, StyledHatIntro, StyledWrapperImg } from "../Post/Elements";
import Moment from "react-moment";
import { BodySeparator } from "../../slices";
import { StyledSeparator } from "../../slices/BodySeparator/Elements";
import ListingJob from "../../components/ListingJob";

const Emploi = ({ data: { prismicEmploi, posts, jobs }, location }) => {
  const { data } = prismicEmploi;

  return (
    <Layout customSEO>
      <SEO
        title={`${data.title.text} | ${website.titleAlt}`}
        pathname={location.pathname}
        desc={data.shortdesc.text}
        banner={data.image}
        node={prismicEmploi}
        article
      />
      <Header light />
      <StyledWrapperImg>
        <Img fluid={data.image.localFile.childImageSharp.fluid} />
      </StyledWrapperImg>
      <StyledHat>
        <Wrapper>
          <div dangerouslySetInnerHTML={{ __html: data.title.html }} />
          <div><span style={{fontWeight: 'bold'}}>Rémunération : </span>{data.remuneration}</div>
          <div><span style={{fontWeight: 'bold'}}>Lieu : </span>{data.localisation}</div>
          <div><span style={{fontWeight: 'bold'}}>Type de contrat : </span>{data.type}</div>
          <div><span style={{fontWeight: 'bold'}}>Date de publication : </span>{data.date}</div>
          <ShareVirality shareUrl={`${website.url}${location.pathname}`} />
        </Wrapper>
      </StyledHat>
      <StyledWrapper>
        <StyledSeparator />
      </StyledWrapper>
      <StyledWrapper>
        <SliceZone allSlices={data.body} />
      </StyledWrapper>
      <StyledWrapper>
        <a  href="mailto:recrutement@confluenceconseil.fr">Je postule</a>
      </StyledWrapper>
      <StyledWrapper>
        <StyledSeparator />
      </StyledWrapper>
      <Wrapper>
        {/*<h3 style={{ marginTop: '4rem' }}>Nos autres emplois</h3>*/}
        <Title title="<h3>Nos autres offres d'emploi</h3>" color="blanc" />
        <ListingJob jobs={jobs.edges} />
      </Wrapper>
    </Layout>
  );
};

export default Emploi;

Emploi.propTypes = {
  data: PropTypes.shape({
    prismicEmploi: PropTypes.object.isRequired,
    posts: PropTypes.object.isRequired,
    jobs: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query EmploiBySlug($uid: String!) {
      prismicEmploi(uid: { eq: $uid }) {
      uid
      data {
        title {
          html
          text
        }
        shortdesc {
          text
          html
        }
        remuneration
        localisation
        type
        date
          
              image {
                  localFile {
                      childImageSharp {
                          fluid(maxWidth: 1200, quality: 90) {
                              ...GatsbyImageSharpFluid_withWebp
                          }
                      }
                  }
                  url
                  copyright
                  alt
              }
          
        image {
            localFile {
              childImageSharp {
                resize(width: 1200, height: 675, quality: 80) {
                  src
                }
              }
            }
            url
          thumb {

              localFile {
                  childImageSharp {
                      fluid(maxWidth: 300, quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
          }
          }  

        body {
          ... on PrismicEmploiBodyText {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicEmploiBodyQuote {
            slice_type
            id
            primary {
              quote {
                html
                text
              }
              author
            }
          }
#          ... on PrismicEmploiBodyCodeBlock {
#            slice_type
#            id
#            primary {
#              code_block {
#                html
#              }
#            }
#          }
          ... on PrismicEmploiBodyVideo {
            slice_type
            id
            primary {
              video {
                html
              }
            }
          }
          ... on PrismicEmploiBodyImage {
            slice_type
            id
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    posts: allPrismicPost(limit: 3, sort: { fields: [data___date], order: DESC }, filter: { uid: { ne: $uid } }) {
      edges {
        node {
          uid
          first_publication_date
          last_publication_date
          data {
            title {
              text
            }
            shortdesc {
              text
            }
            main {
              full {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            date(formatString: "DD.MM.YYYY")
            categorie {
              document {
                uid
                data {
                  name
                  color
                }
              }
            }
            authors {
              author {
                id
                uid
                document {
                  data {
                    name
                    image {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
      jobs: allPrismicEmploi (filter: { uid: { ne: "dummy" } }, sort: { fields: [last_publication_date], order: DESC }) {
          edges {
              node {
                  uid
                  last_publication_date
                  data {
                      date
                      localisation
                      remuneration
                      title {
                          html
                          text
                      }
                      image {
                          thumb {
                              alt
                          }
                      }
                      type
                      shortdesc {
                          html
                          text
                      }
                  }
              }
          }
      }
  }
`;
