import styled from '@emotion/styled'
import { Wrapper } from '../../components'
import { Link } from 'gatsby'
export const StyledHat = styled.section`
    padding 3rem 0 2rem 0;
    text-align: center;
  h1 {
    margin-bottom: 1rem;
    font-family: Muli;
    font-size: 2.22rem;
    line-height: 3.4rem;
    color: ${props => props.theme.colors.primary};
    max-width: 960px;
    display: inline-block;

    span{
        &.vert {
        color: ${props => props.theme.colors.yggVert};
      }

      &.bleuClair {
        color: ${props => props.theme.colors.yggBleuClair};
      }

      &.bleu {
        color: ${props => props.theme.colors.yggBleu};
      }

      &.or {
        color: ${props => props.theme.colors.yggOr};
      }

      &.violet {
        color: ${props => props.theme.colors.primary};
      }

      &.bronze {
        color: ${props => props.theme.colors.yggBronze};
      }
      &.cuivre {
        color: ${props => props.theme.colors.yggCuivre};
      }
      &.magenta {
        color: ${props => props.theme.colors.yggMagenta};
      }

      &.turquoise {
        color: ${props => props.theme.colors.yggTurquoise};
      }
      &.rouge {
        color: ${props => props.theme.colors.yggRouge};
      }
    }
  }

  a {
    color: ${props => props.theme.colors.text};
    font-style: normal;
    font-weight: normal;

    &.author {
      text-transform: capitalize;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
`
export const StyledHatIntro = styled.section`
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    font-size: 1.1rem;
    line-height: 2.0rem;

        span{
            &.vert {
            color: ${props => props.theme.colors.yggVert};
          }

          &.bleuClair {
            color: ${props => props.theme.colors.yggBleuClair};
          }

          &.bleu {
            color: ${props => props.theme.colors.yggBleu};
          }

          &.or {
            color: ${props => props.theme.colors.yggOr};
          }

          &.violet {
            color: ${props => props.theme.colors.primary};
          }

          &.bronze {
            color: ${props => props.theme.colors.yggBronze};
          }
          &.cuivre {
            color: ${props => props.theme.colors.yggCuivre};
          }
          &.magenta {
            color: ${props => props.theme.colors.yggMagenta};
          }

          &.turquoise {
            color: ${props => props.theme.colors.yggTurquoise};
          }
          &.rouge {
            color: ${props => props.theme.colors.yggRouge};
          }
        }
`

export const Headline = styled.div`
  color: ${props => props.theme.colors.white};
  font-size: 1.25rem;
`
export const StyledPost = styled.div`
  h2{
    font-size: 1.6rem;
    color: ${props => props.theme.colors.primary};
  }
`

export const StyledWrapper = styled(Wrapper)`
position: relative;

    .center-align{
        display: block;
    }



  blockquote {
    font-size: 1.2rem;

    width: 80%;
    display: block;
    margin: 1.8rem auto;
    color: ${props => props.theme.colors.primary};


    p {
      font-style: italic;
      font-weight: bold;
      margin-bottom: 0;
    }

    p::before,
    p::after {
      content: '“';
      font-family: Georgia;
      font-size: 4rem;
      margin: -1.4rem 0 0 -3rem;
      position: absolute;
      opacity: 0.5;
    }

    p::after {
      content: '”';
      margin: -0.6rem -5rem 0 0;
    }

    cite {
      font-size: 1.5rem;
    }
  }
`
export const StyledWrapperImg = styled(Wrapper)`
    max-width: 1024px;
    padding: 0;
    margin-top: 1rem;
`
export const StyledContact = styled(Link)`
//   .button{
//     text-align: center;
//     display: block;
//   }
//     a{
      font-size: 0.7rem;
      line-height: 2.6rem;
      font-weight: bold;
      text-transform: uppercase;
      transition: all 0.5s;
      display: inline-flex;
      position: relative;
      padding-left: 13px;
      padding-right: 13px;
      border-radius: 3px;
      color: ${props => props.theme.colors.primary};
      border: 2px solid ${props => props.theme.colors.primary};
      transition: all 0.3s;
      margin: 0 auto 1rem auto;
      text-align: center;

        &:hover{
          color: ${props => props.theme.colors.white}!important;
          background-color: ${props => props.theme.colors.primary};
        }
//   }
`