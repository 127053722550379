import styled from '@emotion/styled';
import { Wrapper } from '../../components';

export const StyledWrapper = styled(Wrapper)`
    padding-top: 2rem;
    padding-botton: 2rem;

  .button{
    text-align: center;
    display: block;
  }
    a{

      font-size: 0.7rem;
      line-height: 2.6rem;
      font-weight: bold;
      text-transform: uppercase;
      transition: all 0.5s;

      position: relative;
      padding-left: 13px;
      padding-right: 13px;
      border-radius: 3px;
      color: ${props => props.theme.colors.primary};
      border: 2px solid ${props => props.theme.colors.primary};
      transition: all 0.3s;
      margin: 0 auto 1rem auto;
      text-align: center;
    display: block;
    width: fit-content;
        &:hover{
          color: ${props => props.theme.colors.white}!important;
          background-color: ${props => props.theme.colors.primary};
        }
  }

    p{
        line-height: 1.8rem;
    }

    h2{
        font-size: 1.6rem;
        color: ${props => props.theme.colors.primary};
    }

  blockquote {
    font-size: 1.2rem;

    width: 80%;
    display: block;
    margin: 1.8rem auto;
    color: ${props => props.theme.colors.primary};

    p {
      font-style: italic;
      font-weight: bold;
      margin-bottom: 0;
    }

    p::before,
    p::after {
      content: '“';
      font-family: Georgia;
      font-size: 4rem;
      margin: -1.4rem 0 0 -3rem;
      position: absolute;
      opacity: 0.5;
    }

    p::after {
      content: '”';
      margin: -0.6rem -5rem 0 0;
    }

    cite {
      font-size: 1.5rem;
    }
  }
`;

